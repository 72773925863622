<template>
  <div>
    <!-- Credit card -->
    <div v-if="!isPaymentPage" class="inline"></div>
    <div
      :class="[
        !isPaymentPage && (listCardSortDefault.length > 0 || listNetsCard.length > 0)
          ? 'main-card-customer-details'
          : 'main-card'
      ]"
    >
      <div class="title">
        <ion-text v-if="listCardSortDefault.length || listNetsCard.length">{{ $t('card_list') }}</ion-text>
      </div>
      <ion-list class="ion-no-padding">
        <div>
          <card-item :cards="listCardSortDefault" @update-card="handleUpdateCard" :defaultCard="defaultCard">
          </card-item>
        </div>
      </ion-list>
      <!-- Credit card -->
      <!-- start btn -->
      <div class="btn-form-payment" v-if="isPaymentPage">
        <ion-button mode="md" fill="outline" @click="$emit('skip')" class="mt-5 fw-500">{{
          $t('back')
        }}</ion-button>
        <ion-button
          mode="md"
          @click="$emit('handleAddCard', listCardSortDefault, defaultCard)"
          class="mt-5 fw-500"
          >{{ $t('next') }}</ion-button
        >
      </div>
      <!-- end btn -->

      <!-- modal add card -->
      <!-- isOpenModalAddRef -->
      <ion-modal
        mode="md"
        :is-open="isOpenModalAddRef"
        css-class="modal-add-card"
        @didDismiss="setOpenModalAdd(false)"
      >
        <modal-add-card
          :isNetCard="listNetsCard.length > 0"
          :infoCard="infoCard"
          :isEdit="isEdit"
          :isDefaultCard="isDefault"
          @edit-card="handleEditCard"
          @add-card="handleAddCard"
          @remove-card="handleDeleteCard"
          @handleCloseModal="setOpenModalAdd(false)"
          @close-modal="setOpenModalAdd(false)"
        ></modal-add-card>
      </ion-modal>
      <ion-loading
        mode="ios"
        :is-open="isOpenRef"
        cssClass="my-custom-class"
        :message="`${$t('please_wait')}. . .`"
        :duration="timeout"
        @didDismiss="setOpen(false)"
      >
      </ion-loading>
    </div>
  </div>
</template>

<script>
import { apolloClient } from '@/main';
import { alertController, toastController } from '@ionic/vue';
import { addCircleOutline, cardOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ModalAddCard from './partials/ModalAddCard.vue';
import CardItem from './partials/NewCardItem.vue';

import {
  addStripeCard,
  addStripeCardAddCustomer,
  getDefaultCard,
  getListCustomerCard,
  removeCardByStripeCustomerId,
  removeStripeCard,
  setDefaultCard,
  setDefaultCardByStripeCustomerId
} from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'CardList',
  components: {
    // User defined components
    CardItem,
    ModalAddCard
  },
  data() {
    return {
      listCardByCustomerId: [],
      defaultCard: '',
      listCardSortDefault: [],
      isOpenModalAddRef: false
    };
  },
  setup() {
    const { t } = useI18n();
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    const infoCard = ref();
    const isDefault = ref(false);
    const isEdit = ref(false);
    return {
      cardOutline,
      addCircleOutline,
      isOpenRef,
      setOpen,
      t,
      infoCard,
      isDefault,
      isEdit
    };
  },

  props: [
    'customerDetailsEdit',
    'isOpenModalAddCard',
    'isPaymentPage',
    'listCardSortDefaultProps',
    'defaultCardProps',
    'stripeCustomerIdProps'
  ],
  computed: {
    ...mapGetters(['listNetsCard', 'stripeId', 'listCardByStripeId'])
  },
  watch: {
    isOpenModalAddCard() {
      if (this.isOpenModalAddCard) {
        this.isEdit = false;
        this.isDefault = false;
        this.setOpenModalAdd(true);
      }
    }
  },
  async mounted() {
    await this.handleGetlistCardByStripeCustomerId();
    await this.handlelistCardSortDefault();
    await this.handleSaveDefaultCard();
  },
  methods: {
    ...mapActions([ACTIONS.SET_STRIPE_ID, ACTIONS.GET_LIST_CARD_BY_STRIPE_ID]),
    handleUpdateRadio(event) {
      this.defaultCard = event;
    },
    setOpenModalAdd(params) {
      this.isOpenModalAddRef = params;
    },
    async handlelistCardSortDefault() {
      let listCardSort = [...this.listCardByCustomerId];
      const defaultCard = listCardSort.find((item) => item.id === this.defaultCard);

      if (defaultCard) {
        listCardSort = listCardSort.filter((item) => item.id !== this.defaultCard);
        listCardSort.unshift(defaultCard);
      }
      this.listCardSortDefault = listCardSort;
    },
    handleUpdateCard(payload, isDefault) {
      this.infoCard = payload;
      this.isEdit = true;
      this.isDefault = isDefault;
      this.setOpenModalAdd(true);
    },

    async handleGetDefaultCard() {
      try {
        await this.setOpen(true);
        const defaultCard = await apolloClient.query({
          query: getDefaultCard,
          variables: {
            customerId: this.customerDetailsEdit.id
          }
        });
        let listCustomerCard = await apolloClient.query({
          query: getListCustomerCard,
          variables: {
            customerId: this.customerDetailsEdit.id
          }
        });
        this.defaultCard = defaultCard.data.getDefaultCardByCustomer?.cardId;
        this.listCardByCustomerId = listCustomerCard.data.listCardByCustomerId.data;
        await this.handlelistCardSortDefault();
        await this.setOpen(false);
      } catch {
        await this.setOpen(false);
      }
    },
    //get card when user add branch
    async handleGetlistCardByStripeCustomerId() {
      if (this.stripeId) {
        await this[ACTIONS.GET_LIST_CARD_BY_STRIPE_ID](this.stripeId);
        this.listCardByCustomerId = this.listCardByStripeId;
      }
    },
    //
    async handleAddCard(card, isDefault) {
      try {
        this.setOpen(true);
        if (this.isPaymentPage) {
          if (this.stripeId) {
            card = {
              stripeCustomerId: this.stripeId,
              ...card
            };
          }
          const { data } = await apolloClient.mutate({
            mutation: addStripeCardAddCustomer,
            variables: card
          });
          if (data.addStripeCardB2B.message) {
            this.setOpen(false);
            this.openToast(data.addStripeCardB2B.message);
          } else {
            await this[ACTIONS.SET_STRIPE_ID](data.addStripeCardB2B.stripeCustomerId);
            await this.handleGetlistCardByStripeCustomerId();
            if (isDefault || this.listCardByStripeId.length === 1) {
              const defaultCardTemp = await apolloClient.mutate({
                mutation: setDefaultCardByStripeCustomerId,
                variables: {
                  stripeCustomerId: data.addStripeCardB2B.stripeCustomerId,
                  cardId: data.addStripeCardB2B.cardId
                }
              });
              this.defaultCard = defaultCardTemp.data.setDefaultCardByStripeCustomerId.cardId;
            }
            await this.handlelistCardSortDefault();
            this.setOpen(false);
            this.openToast();
            this.setOpenModalAdd(false);
          }
        } else {
          await this.setOpen(true);
          this.setOpenModalAdd(false);
          const customerId = {
            customerId: this.customerDetailsEdit.id
          };
          const params = { ...card, ...customerId };
          const { data } = await apolloClient.mutate({
            mutation: addStripeCard,
            variables: params
          });

          if (data.addCardByCustomer.message) {
            this.openToast(data.addCardByCustomer.message);
          } else {
            if (isDefault) {
              this.handleSetDefaultCard(data.addCardByCustomer.cardId);
            }
            this.openToast();
          }

          this.handleGetDefaultCard();
          await this.setOpen(false);
        }
      } catch (e) {
        this.setOpen(false);
        this.presentAlert(e);
      }
    },
    async handleSetDefaultCard(payload) {
      await apolloClient.query({
        query: setDefaultCard,
        variables: {
          cardId: payload,
          customerId: this.customerDetailsEdit.id
        }
      });
      await this.handleGetDefaultCard();
    },
    async handleEditCard(idCard, isDefault) {
      if (!this.isPaymentPage) {
        if (isDefault) {
          this.handleSetDefaultCard(idCard);
        }
      } else {
        if (isDefault) {
          this.defaultCard = idCard;
          await this.handlelistCardSortDefault();
        }
      }
      this.setOpenModalAdd(false);
    },
    async handleDeleteCard($event) {
      await this.setOpenModalAdd(false);
      this.presentAlertConfirm($event);
    },
    async handleRemoveCard(card) {
      try {
        await this.setOpen(true);
        if (this.isPaymentPage) {
          await apolloClient.mutate({
            mutation: removeCardByStripeCustomerId,
            variables: {
              stripeCustomerId: this.stripeId,
              cardId: card
            }
          });
          await this.handleGetlistCardByStripeCustomerId();

          //when list card temp = 0 reset stripeId
          if (this.listCardByCustomerId.length === 0) await this[ACTIONS.SET_STRIPE_ID](null);
          if (card === this.defaultCard) this.defaultCard = '';
          this.handlelistCardSortDefault();
        } else {
          await apolloClient.mutate({
            mutation: removeStripeCard,
            variables: {
              customerId: this.customerDetailsEdit.id,
              cardId: card
            }
          });
        }
        this.openToast(this.t('removedSuccessfully'));
        this.handleGetDefaultCard();
        await this.setOpen(false);
      } catch (e) {
        this.presentAlert(e);
      }
    },
    async handleSaveDefaultCard() {
      if (this.stripeId) {
        try {
          if (this.isPaymentPage) {
            const { data } = await apolloClient.mutate({
              mutation: setDefaultCardByStripeCustomerId,
              variables: {
                stripeCustomerId: this.stripeId,
                cardId: this.listCardByCustomerId[0].id
              }
            });
            this.defaultCard = data.setDefaultCardByStripeCustomerId.cardId;
            this.handlelistCardSortDefault();
          } else {
            await apolloClient.mutate({
              mutation: setDefaultCard,
              variables: {
                customerId: this.customerDetailsEdit.id,
                cardId: this.defaultCard
              }
            });
            this.handleGetDefaultCard();
            this.$emit('handleCloseModalPayment');
          }
          await this.setOpen(false);
        } catch (e) {
          this.presentAlert(e);
        }
      }
    },
    async presentAlert(message) {
      await this.setOpen(false);
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: message,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    async presentAlertConfirm(event) {
      await this.setOpen(false);
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: this.$t('accountPage.are_you_sure_delete_card'),
        buttons: [
          {
            text: this.$t('yes_confirm'),
            handler: () => {
              this.handleRemoveCard(event);
            }
          },
          {
            text: this.$t('cancel')
          }
        ]
      });
      await alert.present();
    },
    async openToast(message) {
      const toast = await toastController.create({
        mode: 'ios',
        color: 'dark',
        position: 'top',
        message: message || this.$t('saved_successfully'),
        duration: 2000
      });
      return toast.present();
    }
  }
});
</script>

<style lang="scss" scoped>
.credit-card {
  box-shadow: 0 0 3px 1px #f0f0f0;
}
.credit-card .title {
  font-weight: 500;
  color: #555555;
  margin-bottom: 10px;
  font-size: 16px;
}

.add-box {
  background: #f9fff6;
  padding-left: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.add-box .inner-text {
  font-size: 13px;
  color: #888888;
  margin-left: 5px;
}
.add-box .col {
  padding-top: 10px;
}
</style>

<style scoped lang="scss">
.modal-add.custom-modal .modal-wrapper {
  border-radius: 20px 20px 0 0;
}
.modal-add.custom-modal .modal-shadow {
  display: none;
}

.modal-remove.custom-modal .modal-wrapper {
  margin: 24px;
  height: 240px;
  border-radius: 12px;
}
.modal-remove.custom-modal .modal-shadow {
  display: none;
}

.modal-success.custom-modal .modal-wrapper {
  margin: 24px;
  border-radius: 4px;
  width: 250px;
}
.modal-success.custom-modal .modal-shadow {
  display: none;
}

.modal-success.custom-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}

.modal-success.custom-modal .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
  padding: 10px;
}

.custom-toast {
  --max-width: 220px;
  --width: auto;
}
.btn-form {
  display: flex;
  justify-content: flex-end;
  & > ion-button {
    height: 45px;
    --border-radius: 10px;
    text-transform: none;
  }
}
.btn-form-payment {
  display: flex;
  justify-content: space-between;
  & > ion-button {
    float: right;
    width: fit-content;
    height: 45px;
    --border-radius: 8px;
    text-transform: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
.main-card {
  & > .title {
    margin: 0px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0px 10px 0px;
  }
}
.main-card-customer-details {
  border-top: 1px solid var(--ion-color-text-gray-300);
  border-bottom: 1px solid var(--ion-color-text-gray-300);
  position: absolute;
  margin-top: 20px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0px 20px;
  & > .title {
    margin: 0px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0px 10px 0px;
  }
}
.inline {
  width: 100%;
  border-bottom: 1px solid var(--ion-color-text-gray-300);
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}
</style>
