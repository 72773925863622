<template>
  <div>
    <div v-if="listNetsCard.length > 0">
      <div class="form-text" v-for="(netCard, index) in listNetsCard" :key="index">
        <div class="pt-2 pb-2 d-flex align-center">
          <div>
            <ion-img class="card-image" :src="NetsCardImage" />
          </div>
          <div class="form-card align-center ml-2">
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <div class="font-style-toolbar-button-semibold">
                    {{ netCard.issuer_short_name }} {{ $t('accountPage.nets_card_type') }}
                  </div>
                  <div class="font-style-toolbar-segment">
                    *{{ netCard.last_4_digits_fpan }} {{ netCard.mtoken_expiry_date.substr(2) }}/{{
                      netCard.mtoken_expiry_date.substr(0, 2)
                    }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <ion-icon :icon="trashOutline" @click="handleRemoveNetsCard" style="color: red" />
                </div>
              </div>
              <div class="align-self-start">
                <div class="mr-1 card-badge">
                  {{ $t('accountPage.default') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-text" v-for="(card, index) in cards" :key="index">
      <div class="pt-2 pb-2 d-flex align-center">
        <div>
          <ion-img class="card-image" :src="cardImg(card.brand)" />
        </div>
        <div class="form-card align-center ml-2">
          <div class="d-flex flex-column w-100">
            <div class="d-flex justify-space-between">
              <div class="d-flex flex-column">
                <div class="font-style-toolbar-button-semibold">{{ card.brand }} ({{ card.funding }})</div>
                <div class="font-style-toolbar-segment">
                  *{{ card.last4 }} {{ card.exp_month }}/{{ card.exp_year }}
                </div>
              </div>
              <div class="d-flex align-center">
                <ion-icon
                  :icon="ellipsisVertical"
                  @click="$emit('update-card', card, defaultCard === card.id ? true : false)"
                />
              </div>
            </div>
            <div class="align-self-start">
              <div v-if="defaultCard === card.id && listNetsCard.length === 0" class="mr-1 card-badge">
                {{ $t('accountPage.default') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
// const { mapGetters } = createNamespacedHelpers('b2b/payment');
import { Device } from '@capacitor/device';

import NetsCardImage from '@/assets/images/nets-brand.png';
import { capitalizeFirstLetter } from '@/modules/b2b/services/libs/helper';
import { stripe } from '@/modules/b2b/services/libs/payment';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { ellipsisVertical, trashOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/payment');
// import Payment from '@/plugins/nets-payment-plugin.js';
import { presentAlert } from '@/modules/shared/utils/';

export default defineComponent({
  props: {
    cards: {
      required: true
    },
    defaultCard: {
      type: String,
      default: null
    },

    isNetCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['listNetsCard', 'status', 'error'])
  },

  setup() {
    const isOpenRef = ref(false);
    const event = ref();
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (value) => (isOpenLoadingref.value = value);
    const setOpen = (state, event) => {
      event.value = event;
      isOpenRef.value = state;
    };

    const deviceId = ref(null);
    return {
      deviceId,
      trashOutline,
      ellipsisVertical,
      isOpenRef,
      setOpen,
      event,
      NetsCardImage,
      capitalizeFirstLetter,
      isOpenLoadingref,
      setOpenLoading
    };
  },
  async created() {
    this.deviceId = (await Device.getId()).uuid;
  },
  methods: {
    ...mapActions([ACTIONS.REMOVE_NETS_CARD, ACTIONS.GET_LIST_NETS_CARD]),
    async handleRemoveNetsCard() {
      await presentAlert({
        mode: 'ios',
        header: 'Delete Card?',
        message:
          'Once you delete the existing NETS Card from this device, gonna be deleted to all companies.',
        buttons: [
          {
            text: 'Cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.removeCardNets();
            }
          }
        ]
      });
    },
    async removeCardNets() {
      // await this.setOpenLoading(true);
      // await this[ACTIONS.REMOVE_NETS_CARD]({
      //   deviceId: this.deviceId
      // });
      // let payload;
      // if (this.status) {
      //   // remove all net card
      //   await Payment.onDeregistration();
      //   // show add NET card again
      //   this.$emit('showAddNetsBankCard');
      //   await this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId);
      //   payload = {
      //     color: TOAST_COLOR.BLACK,
      //     message: this.$t('card_has_been_deleted')
      //   };
      // } else {
      //   payload = {
      //     color: TOAST_COLOR.DANGER,
      //     message: this.error.message
      //   };
      // }
      // this.$emit('showAddNetsBankCard');
      // await this.setOpenLoading(false);
      // await openToast(payload);
    },

    cardImg(brand) {
      return stripe.getCardImage(brand);
    }
  }
});
</script>
<style scoped lang="scss">
.form-text {
  & > div {
    width: 100%;
    & > .form-card {
      width: 100%;
      display: flex;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
ion-icon {
  font-size: 25px;
  float: right;
}
.card-image {
  width: 40px;
  height: auto;
}
.card-badge {
  color: white;
  background: #04565a;
  padding: 4px;
  font-size: 10px;
  border-radius: 3px;
  opacity: 0.9;
}
</style>
